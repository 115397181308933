import React from "react";

function CardComponent({apiResponse}) {
  if(!apiResponse){
    return null;
  }
	return (
		<div className="Card">
			<h1>Output</h1>
			<p>{apiResponse}</p>
		</div>
	);
}

export default CardComponent;
