import React from "react";

function FooterComponent() {
  return (
		<div className="Footer">
			<p>Wait a bit after hitting the button, it can be slow!</p>
			<p>
				Made with{" "}
				<span role="img" aria-label="heart">
					❤️
				</span>{" "}
				by{" "}
				<a
					href="https://twitter.com/felpix_"
					target="_blank"
					rel="noopener noreferrer"
				>
					Felpix
				</a>.
			</p>
		</div>
  );
}

export default FooterComponent;