import React, { useState } from 'react';
const { Configuration, OpenAIApi } = require("openai");
const configuration = new Configuration({
	apiKey: process.env.REACT_APP_API_KEY,
});

function FormComponent({ setAPIResponse, formSubmitted, setFormSubmitted}) {
  const [activityName, setActivityName] = useState('');
  const [position, setPosition] = useState('');
  const [details, setDetails] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  

  const openai = new OpenAIApi(configuration);

  const formSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (activityName === '' || position === '' || details === '') {
      setAPIResponse('You need to write something!');
      setFormSubmitted(true);
      return;
    }else{
      try {
      const response = await openai.createChatCompletion({
			  model: "gpt-3.5-turbo",
			  messages: [
				  { role: "system", content: `You are a college admissions consultant and an expert copywriter. Your task is to write extracurricular descriptions that highlight a student's impact and accomplishments. You will be given an activity name, the student's position or role in the activity, and then a simple description by the student. You must convert this information into a 150 character description of the extracurricular. For example, given:\nActivity Name = Computer Science Club\nPosition = Vice President\nDetails = I built my club's website, organized a hackathon for my school, and helped my classmates with their AP Computer Science homework.\nYour response should be similar to:\nLead weekly meetings. Built the club's website. Organized a Hackathon with over 100 participants. Tutor Python and Java (APCS) to ~30 students.\n If there is no information or the provided information doesn't make sense, respond "You need to write something!".\nThe following is a given set of information. Reply only with your professionally written extracurricular description:` },
				  { role: "user", content: `Activity Name = ${activityName}\nPosition = ${position}\nDetails = ${details}\n` },
			  ],
        max_tokens: 500,
        temperature: 1.3,
		  });
		  console.log(response.data.choices[0].message.content);
      
			console.log(response);
			console.log(response.data.choices[0].message);
			setAPIResponse(response.data.choices[0].message.content);
      setFormSubmitted(true);
      setActivityName('');
      setPosition('');
      setDetails('');
		} catch (err) {
			console.log(err);
		} finally {
      setIsLoading(false);
    }
    }
    
  }

  if(formSubmitted){
    return null;
  }
  
  return (
		<div className="Form">
			<h1>Information</h1>
			<form>
				<div className="form-row">
					<label>Activity Name</label>
				</div>
				<div className="form-row">
					<input
						type="text"
						onChange={(e) => setActivityName(e.target.value)}
					/>
				</div>
				<div className="form-row">
					<label>Position</label>
				</div>
				<div className="form-row">
					<input
						type="text"
						onChange={(e) => setPosition(e.target.value)}
					/>
				</div>
				<div className="form-row">
					<label>What did you do? (In plain English)</label>
				</div>
				<div className="form-row">
					<textarea
						type="text"
						onChange={(e) => setDetails(e.target.value)}
					/>
				</div>

				{isLoading ? (
					<p>Description loading</p>
				) : (
					<button className="button" onClick={formSubmit}>
						Generate my description
					</button>
				)}
			</form>
		</div>
  );
}

export default FormComponent;