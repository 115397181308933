import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRotateRight } from '@fortawesome/free-solid-svg-icons';

function RefreshComponent({ formSubmitted, setFormSubmitted, setAPIResponse}){
  const onRefresh = () => {
    setFormSubmitted(false);
    setAPIResponse('');
  }
  if(!formSubmitted){
    return null;
  }
  return (
    <div className='refreshContainer'>
      <FontAwesomeIcon className='refreshIcon' icon={faRotateRight} onClick={onRefresh}/>
    </div>
  )
}

export default RefreshComponent;