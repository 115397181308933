import React, { useState } from 'react';
import './App.css';

import FormComponent from './FormComponent';
import CardComponent from './CardComponent';
import RefreshComponent from './RefreshComponent';
import FooterComponent from './FooterComponent';

function App() {
  const [apiResponse, setAPIResponse] = useState('');
  const [formSubmitted, setFormSubmitted] = useState(false);

  return (
		<div className="App">
			<div className="ContainerGrid">
				<div className="ContainerCard">
					<RefreshComponent
						formSubmitted={formSubmitted}
						setFormSubmitted={setFormSubmitted}
						setAPIResponse={setAPIResponse}
					/>
					<FormComponent
						setAPIResponse={setAPIResponse}
						formSubmitted={formSubmitted}
						setFormSubmitted={setFormSubmitted}
					/>
					<CardComponent apiResponse={apiResponse} />
          <FooterComponent />
				</div>
			</div>
		</div>
  );
}

export default App;
